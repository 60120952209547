@import 'constants.scss';
 

.storyContainer{

    .storiesBox{
    
        .tdItem{
            height: 100%;
            align-items: flex-start;
            margin-left: -6px;

            .tdItemTitle{
                // margin-bottom: 16px;
                font-size: 14px;
                font-weight: 800;

                // ::placeholder {
                //     font-size: 16px;
                // }
                
            }
        }
        
        .tdItemPadding{
            padding: 7px;
        }
    }

    .jiraUrl{
        color: $primaryColor;
    }
    .linkButton{
        color: $secondaryColor;
        cursor: pointer;
        height: 24px;
    } 

    .snackbarLink{
        font-weight: 600;
    }
}

// New Add button V9

.actionsButtonContainer{
    margin: 6px 0 12px;

    .actionsButton {
        margin-right: 32px;
        z-index: 0;
        color: $primaryColor;
        font-size: 14px;
        font-weight: 600;
        padding: 0;
        justify-content: flex-start;

        &:hover{
            color: $textColor;
            background: none;
        }
    }
}

.requirementTable{
    :global{

        .tableRow{
            .cell{
                vertical-align: top;
            }
        }

        .hasEditableField{
            td{
                border-bottom: none !important;
            }
        }
        .MuiButton-endIcon{
            margin-left: 4px;
        }
    }

    .tdItem{
        :global{
            .tox-tinymce.tox{
                background-color: transparent;
            }
        }
    }

}
// End New Add button V9

.ticketInfoBox{
    // margin-bottom: 25px;
    padding: 0 7px;

    .ticketInfo{
        margin-bottom: 3px;
        // margin-left: 5px;
        .iconButton{
            margin-left: -7px;
        }

        .ticketInfoLabel{
            width: 36px;
            font-size: 10px;
            font-weight: 700;
            line-height: 21px;
            color: #828282;
            margin-right: 3px;
            margin-left: 5px;
        }
    
        .ticketInfoText{
            font-size: 12px;
            color: $textColor;
            font-weight: 500;
            margin-left: 5px;
        }
    }

    .ticketInfoTitle{
        font-size: 12px;
        font-weight: 700;
        color: #5A5A5A;
        margin-bottom: 3px;
    }
}

.dependencyInfo{
    padding: 4px 7px;
    display: flex;
    align-items: center;
    transition: background-color .2s linear;
    border-radius: 100px;

    &:hover{
        background: #EBEBEB;
        text-decoration: none;
    }

    .dependencyInfoLabel{
        font-size: 14px;
        color: $primaryColor;
        margin-right: 3px;
    }

    .dependencyInfoText{
        font-size: 12px;
    }
}

.iconButton{
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #828282;
    min-width: auto;
    border-radius: 6px;
    transition: background-color .2s linear;

    &:hover{
        background: #EBEBEB;
    }

    >svg{
        font-size: 17px;
        margin-left: 4px;
        color: #5a5a5a;
    }
}

.generateStoryDialog{
    width: 720px;
}

.refineTextArea {
    width: 400px;
}