.tableContainer{
    margin-bottom: 56px;

    .title{
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #5A5A5A;
        margin: 0 38px 8px;
    }

    .teamItem{
        background: #EBEBEB; 
        padding: 5px 9px;
        border-radius: 100px;
        margin: 2px 8px 2px 0;
        text-align: center;
    }
    
}

.actionMenu{
    align-self: center;
    .actionMenu-btn {
        margin-left: 16px;
    }
}