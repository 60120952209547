@import 'constants.scss';

.toolbar {
    display: inline-flex;
    background-color: white;
    padding: 2px;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(29, 28, 29, 0.13);
    
    &.outlined {
        box-shadow: 0px 1px 3px 0px $primaryColor, 0px 0px 0px 1px $primaryColor;
    }
}

.avatar-group {
    display: flex;
    cursor: pointer;

    & > * {
        width: 30px !important;
        height: 30px;
        font-size: 12px;
        background-color: #003138;
        font-weight: 500;
    }
}

.toolbar-button {
    display: flex;
    padding: 7px;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &:hover {
        background-color: rgba(29, 28, 29, 0.13);
    }
}
