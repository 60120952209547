@import 'constants.scss';

.userAccountContainer {
    cursor: pointer;

    .userInitials {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-size: 12px;
        color: $white;
        background-color: $primaryColorDark;
    }
}

.menuPaper{
    box-shadow: 0px 0px 6px 4px rgba(53, 53, 53, 0.25);
}

.menuContainer {
    .header {
        padding-left: 12px;
        font-size: 12px;
        color: #5A5A5A;
        font-weight: bold;
        margin-bottom: 13px;
    }

    .userInfoContainer {
        padding-left: 12px;
        margin-bottom: 13px;
        padding-right: 12px;
        .userInitials {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            font-size: 14px;
            color: $white;
            background-color: $primaryColorDark;
            margin-right: 8px;
    
            align-items: center;
            justify-content: center;
        }
        .name {
            font-size: 14px;
            color: $secondaryColor
        }

        .email {
            font-size: 12px;
        }
    } 

    .logoutContainer {
        color: $secondaryColor;
        font-size: 14px;
        align-items: center;
        .logoutIcon {
            margin-right: 10px;

            svg {

                width: 14px;
                height: 14px;
            }
        }
    }
}