@import 'constants.scss';

.fileUploader {
    flex-direction: column;
    .fileInput {
        display: none;
    }
    
    .attachBtn{
        font-size: 12px;
        font-weight: 700;
        margin-top: 4px;
    
        :global{
            .MuiButton-startIcon{
                margin-right: 4px;
    
                *:nth-of-type(1){
                    font-size: 16px;
                }
            }
        }
    }

 }