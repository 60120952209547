@import 'commonStyles.scss';
@import 'constants.scss';

.progressBar {
    width: 128px;
    gap: 8px;
    color: #404040;

    p {
        font-size: 13px;
    }

    .progressBar-linearProgress {
        display: block;
        width: 100%;

        & :global(.MuiLinearProgress-root) {
            height: 6px;
            border-radius: 20px;
            background-color: $surface1;
        }

        & :global(.accentSuccess-bgColor) {
            background-color: $accentSuccess;
        }

        & :global(.accentCaution-bgColor) {
            background-color: $accentCaution;
        }

        & :global(.accentError-bgColor) {
            background-color: $accentError;
        }
    }
}