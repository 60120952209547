@import 'commonStyles.scss';
@import 'constants.scss';

.viewsTableContainer {
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.customHeader {
    color: #2F3B31;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.initiativeCell {
    gap: 8px;
    height: 100%;
    padding: 16px 0px;

    .initiativeCell__numbers {
        color: #A09FED;
        justify-content: flex-start;

        p {
            font-size: 13px;
        }
    }
}

.sourcesContainer {
    height: 100%;

    .sourcesBox {
        gap: 2px;
        padding: 4px 8px;
        height: 28px;
        border-radius: 8px;
    }
}