@import 'constants.scss';

.wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 38px;

    .diagram {
        flex-grow: 1;
    }
      
    .overview{
        z-index: 99;
        position: absolute;
        left: 38px;
        top: 0;
        width: 180px;
        height: 100px;
        background-color: #FBFBFB;
        border: 1px solid $primaryColor;
    }
}