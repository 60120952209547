@import 'constants.scss';

.inputComponentMainContainer {
    .label {
        font-size: 12px;
        line-height: 21px;
        color: $formElementLabel;
        .required{
            color: $requiredFieldColor;
            font-size: 14px;
        }
    }

    .placeholder{
        color: #828282;
        opacity: .8;
    }

    .inputRoot {
        .MuiInput-underline {
            font-size: 14px;
            &::after {
                border-bottom: none !important
            }
            &::before {
                border-bottom: none !important;
            }
            
        }
        &.errorField {
            .MuiOutlinedInput-root {
                border-color: $fieldErrorColor;

                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $fieldErrorColor;
                }

                &.Mui-focused {
                    background: #fff;
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid $fieldErrorColor;
                    }
                }
                &.Mui-focused fieldset {
                    border-color: $fieldErrorColor;
                }
            }
        }
    
        .MuiOutlinedInput-root {
            height: 44px;
            background: $formElementBackground;
            font-size: 14px;
            border-radius: 6px;

            
            &.Mui-focused fieldset {
                border-color: $secondaryColor;
            }
            .MuiChip-root{
                .MuiSvgIcon-fontSizeMedium{
                    font-size: 14px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: transparent;
            }
        
            &:hover{
                background: #E8E8E8;
            }

            &.Mui-focused {
                background: #fff;
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $primaryColor;
                }
            }

        }

        .MuiFilledInput-root{
            background-color: transparent;
            font-size: 14px;
            border: 1px solid transparent;
            border-radius: 6px;
            
            &::after{
                border-bottom: none;
            }
            &::before {
                border-bottom: none !important;
            }

            .MuiAutocomplete-popupIndicator{
                opacity: 0;
            }

            &:hover{
                background: #E8E8E8;
                .MuiAutocomplete-popupIndicator{
                    opacity: 1;
                }
            }

            &.Mui-disabled{
                &:hover{
                    background: inherit;
                }
                .MuiAutocomplete-popupIndicator{
                    opacity: 0;
                }
            }

            &.Mui-focused{
                background: #fff;
                border-color: $primaryColor;
                .MuiAutocomplete-popupIndicator{
                    opacity: 1;
                }
            }

            .MuiFilledInput-input{
                padding: 7px;
                height: inherit;
            }
            .MuiAutocomplete-endAdornment{
                .MuiSvgIcon-fontSizeSmall{
                    font-size: 14px;
                }
            }
            .MuiChip-root{
                height: 26px;
                .MuiChip-label{
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 12px;
                }
                .MuiSvgIcon-root{
                    font-size: 16px;
                }
            }
        }
    

   
    }

    .inputText {
        cursor: text;
        padding: 7px;
        border-radius: 6px;
        border: 1px solid transparent;
        transition: background-color .2s linear;

        &.editableActive:hover {
            background: #E8E8E8;
        }
    }    

    .error {
        font-size: 12px;
        color: $fieldErrorColor;
    }
}
