@import 'constants.scss';

.tabsContainer {
    &.root {

        min-height: 26px;

        .indicator {
            background-color: $primaryColorDark;
        }
    }
}