@import 'commonStyles.scss';
@import 'constants.scss';

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.viewsGroupContainer {
    max-height: 154px;
    overflow-y: auto;
}