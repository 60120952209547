@import 'constants.scss';

.mainContainer {
    height: 100%;

    .datePickerBox {
        align-self: center;
    }

    .tableContainer {
        align-items: flex-start;
        width: 100%;
        height: 100%;
    }

    .actionMenu {
        button {
            margin-left: 16px;
        }
    }

    .scoreBox {
        width: 43px;
        height: 27px;
        border-radius: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
    }
}

// Colors
.mediumGreen {
    color: $mediumGreen;
}

.darkGreen {
    color: $darkGreen;
}

.lightYellow {
    color: $lightYellow;
}

.mediumYellow {
    color: $mediumYellow;
}

.darkYellow {
    color: $darkYellow;
}

.lightOrange {
    color: $lightOrange;
}

.mediumOrange {
    color: $mediumOrange;
}

.darkOrange {
    color: $darkOrange;
}

.lightRed {
    color: $lightRed;
}

.mediumRed {
    color: $mediumRed;
}

.darkRed {
    color: $darkRed;
}

.darkestRed {
    color: $darkestRed;
}

.mediumGreenBg {
    background-color: $mediumGreen;
}

.darkGreenBg {
    background-color: $darkGreen;
}

.lightYellowBg {
    background-color: $lightYellow;
}

.mediumYellowBg {
    background-color: $mediumYellow;
}

.darkYellowBg {
    background-color: $darkYellow;
}

.lightOrangeBg {
    background-color: $lightOrange;
}

.mediumOrangeBg {
    background-color: $mediumOrange;
}

.darkOrangeBg {
    background-color: $darkOrange;
}

.lightRedBg {
    background-color: $lightRed;
}

.mediumRedBg {
    background-color: $mediumRed;
}

.darkRedBg {
    background-color: $darkRed;
}

.darkestRedBg {
    background-color: $darkestRed;
}

.white {
    color: $white;
}

.secondaryColor {
    color: $secondaryColor;
}

.scoreGreenBg {
    background-color: $scoreGreen;
}

.scoreRedBg {
    background-color: $scoreRed;
}

.kbSidepanel {
    padding-left: 16px;
}