.comments-sidebar {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;

    .content-container {
        flex: 1;
        overflow-y: auto;
    }
}