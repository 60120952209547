@import 'constants.scss';

.mainContainer {
    height: 100%;
    .breadcumbsContainer {
        padding: 32px 38px 0px;
        color: #828282;
        font-size: 14px;

        .first {
            margin-right: 10px;
            color: #C4C4C4;
            cursor: pointer;
        }

        .second {
            margin: 0 10px;
            cursor: pointer;
        }
        .third {
            margin-left: 10px;
            color: #5A5A5A;
            cursor: pointer;
        }
    }

    .tableContainer {
        align-items: flex-start;
        width: 100%;
        height: 100%;

        .fieldMapping {
            color: $primaryColorDark;
            font-size: 16px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 0px 38px 16px;

        }

        .actionsButtonContainer{
            margin: 6px 0 12px;
        
            .actionsButton {
                margin-right: 32px;
                z-index: 0;
                color: $primaryColor;
                font-size: 14px;
                font-weight: 600;
                padding: 0;
                justify-content: flex-start;
        
                &:hover{
                    color: $textColor;
                    background: none;
                }
            }
        }
        
    }
}