@import 'constants.scss';
@import 'commonStyles.scss';

.messageSidePanel {
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid $surface2;
    border-radius: 12px;
    margin: 20px;
    padding: 20px;
    height: calc(100vh - 69px);

    .sidePanel-mainContent {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
    }

    .prdPreview-header {
        padding-bottom: 20px;
        gap: 4px;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
    }

    .prdPreview-title {
        font-size: 18px;
        font-weight: 600;
    }

    .closeIcon {
        cursor: pointer;
        font-size: 20px;
        color: #828282;
    }

    .smallIcon {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .refine-btn {
        align-self: flex-end;
        background-color: $surface1;
        border-radius: 8px;
        padding: 4px 8px;
        font-size: 12px;
    
        svg {
            width: 16px;
            height: 16px;
            path {
                fill: $primaryColor
            }
        }
        
        &:hover {
            background-color: $surface2;
        }
    }

    .prdFieldLabel {
        text-transform: capitalize;
        color: $primaryColorDark;
    }
}