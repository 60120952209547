@import 'constants.scss';

.issueLinkContainer {
    align-items: flex-start;
    margin: 5px 0 16px;

    .issueLinkButton {
        height: 36px;

        .buttonLoader{
            margin-left: 5px;
        }
    }

    .inputContainer {
        word-break: break-all;
        width: 315px;
        margin-right: 8px;
        background: #F5F5F5;
        color: $primaryColor;
        box-sizing: content-box;

        :global {
            .inputText {
                border: 1px solid #C4C4C4;
            }

            .inputRoot {
                .MuiOutlinedInput-root {
                    height: 36px;
                }
            }
        }
    }
}

.jiraMappingLink{
    margin-left: 5px;
    color: $linkColor;
}