@import 'constants.scss';

.listboxes{
    font-size: 14px;
}

.selectContainer {
    .label {
        font-size: 12px;
        line-height: 21px;
        color: $formElementLabel;

        .required{
            color: $requiredFieldColor;
            font-size: 14px;
        }
    }

    .disabled{
        background: #FAFAFA;
        padding: 11px 12px;
        font-size: 14px;
        border-radius: 6px;
        min-height: 40px;
        color: $textColor;
        flex-wrap: wrap;

        &.placeholder{
            color: #828282;
            opacity: .8;
        }

        .chip{
            font-size: 12px;
            border-radius: 16px;
            color: rgba(0, 0, 0, 0.87);
            background-color: rgba(0, 0, 0, 0.08);
            align-items: center;
            padding: 0px 10px;
            margin: 2px;
        }
    }

    .autocomplete {
        .inputRoot {
    
            &.MuiAutocomplete-inputRoot {
                padding: 0px;
            }
            .MuiOutlinedInput-root { 
                min-height: 40px;
                height: unset;
                font-size: 14px;
                padding-right: 35px;
            }
        }
    }

}