@import 'constants.scss';

.tabContainer {
    &.root {
        font-size: 16px;
        min-height:26px;
        padding: 0px;
        margin-right: 45px;
        min-width: unset;
        color: $secondaryColor;

        &.selected {
            color: $primaryColorDark;
            font-weight: bold;
        }
    }
}