@import 'constants.scss';
@import 'commonStyles.scss';

.linkingNodesContainer {

    a {
        color: $primaryTextColor;
        text-decoration: none;
    }

    & :global(.MuiPaper-root) {
        max-width: 538px !important;
    }

    .linkedNode {
        width: 458px;
        padding: 12px;
        background-color: $surface1;
        border-radius: 12px;
        gap: 8px;

        &:hover {
            background-color: $surface2;
        }
    }

    .deleteIcon {
        padding: 6px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}