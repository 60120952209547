@import 'constants.scss';
@import 'commonStyles.scss';

.menuItemContainer {
    text-decoration: none;
    color: $primaryTextColor;
    border-radius: 12px;

    .popupMenuItemTitle {
        div {
            max-width: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }
    }

    .menuItem {
        align-items: center;
        justify-content: flex-start;
        padding: 10px 12px;
        gap: 8px;
        cursor: pointer;

        p {
            max-width: 160px;
        }

        svg {
            width: 22px;
            flex-shrink: 0;
        }
    }

    .menuItem__settings {
        .settingsBtn {
            display: none;
        }

        &:hover {
            button {
                display: flex;

                &:hover {
                    background-color: inherit;
                }
            }
        }
    }

    &.active {
        color: $primaryColor;
        background: $listItemActiveBackground;
    }

    &:hover {
        background: $listItemHoverBackground;
    }
}

.menu-accordion {
    &:global(.MuiPaper-root.MuiAccordion-root) {
        box-shadow: none;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        p {
            max-width: 160px;
        }
        svg {
            width: 22px;
            flex-shrink: 0;
        }
    }
    
    a {
        text-decoration: none;
    }


    :global(.MuiAccordionSummary-root) {
        padding: 0;
        text-decoration: none;
        color: $primaryTextColor;
        min-height: unset;
        padding: 10px 12px;
        border-radius: 12px;

        &.active {
            color: $primaryColor;
            background: $listItemActiveBackground;
        }
    
        &:hover {
            background: $listItemHoverBackground;
        }
    }
    
    :global(.MuiAccordionSummary-content) {
        margin: 0;
    }

    :global(.MuiAccordionSummary-content.Mui-expanded) {
        margin: 0;
        min-height: unset
    }

    :global(.MuiAccordionDetails-root) {
        padding: 10px 40px;
        margin-top: 4px;
        border-radius: 12px;

        &.collapsed {
            padding: 10px 20px;
        }

        &.active {
            background: $listItemActiveBackground;
            p {
                color: $brandPrimaryColor;
            }
        }
    
        &:hover {
            background: $listItemHoverBackground;
            p {
                color: $brandPrimaryColor;
            }
        }
    }
}
