@import 'constants.scss';
@import 'commonStyles.scss';


.tableContainer {
    height: 100%;
    width: 100%;
}

.templateContainer {
    padding: 12px 38px;

    .templateItem {
        align-items: flex-start;
        background-color: rgba($color: $surface1, $alpha: 0.5) ;
        border: 1px solid $surface2;
        border-radius: 12px;
        padding: 20px 24px;
    }

    :global(.table) {
        border: 1px solid $surface2;
        border-radius: 12px 12px 0px 0px;
    }

    :global(.table .tableHead .MuiTableRow-head .MuiTableCell-head:first-child) {
        border-radius: 12px 0px 0px 0px;
    }

    :global(.table .tableHead .MuiTableRow-head .MuiTableCell-head:last-child) {
        border-radius: 0px 12px 0px 0px;
    }

    :global(.tableRow) {
        background-color: #fff;
    }

    :global(.tableRow.odd) {
        background-color: #fff;
    }
}

