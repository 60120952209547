@import 'constants.scss';

.container {
    background: rgba(229, 229, 229, 0.24);
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    height: 40px;

    &:hover {
        border: 1px solid $primaryColor;
    }

    &.checked {
        background: $primaryColor;
        color: #FFFFFF;
    }
}