@import 'constants.scss';

.resourcesIconBox{
    margin-bottom: 40px;
    padding: 0 38px;

    .icon{
        cursor: pointer;
        margin-right: .9rem;
    }
}

.editLinkBox {
    
    margin: 20px 0 40px;
}

.resourcesLinksContainer{
    padding: 0 38px 32px;
}

.resourcesImgContainer{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 38px;

    .previewImage {
        height: 170px;
        width: 100%;
        object-fit: contain;
        background: #FBFBFB;
        outline: 1px solid #D9D9D9;
        cursor: pointer;
        &.googleDoc {
            color: #4885ed;
        }
        &.pdf {
            color: #F40F02;
        }
        &.fileIcon{
            color: #5A5A5A;
        }
        &.imageType{
            cursor: zoom-in;
        }
    }

    .resourcesImgItem{
        position: relative;

        .imgItemOverlay{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: $white;
            background: rgb(53 53 53 / 45%);
            padding: 8px;
            justify-content: space-between;
            opacity: 0;
            transition: all .3s;
        }

        &:hover{
            .imgItemOverlay{
                opacity: 1;
            }
        }

        .resourcesCloseIcon{
            cursor: pointer;
            font-size: 18px;
        }

        .resourcesImgDesc{
            font-size: 12px;
            word-break: break-all;
            margin-right: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
                    line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.circularProgress{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(219 219 219 / 70%);
}
