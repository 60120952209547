@import 'constants.scss';

.integrationsContainer {
    
    .integrationCardContainer {
        justify-content: space-between;
        padding: 0 38px;

        .name {
            font-size: 24px;
            margin-bottom: 16px;
            svg {
                width: 32px;
                height: 34px;
                margin-right: 8px;
            }
        }

        .description {
            font-size: 14px;
        }

        .integrationDisconnectButton{
            background: $requiredFieldColor;
        }

        .jiraMapping {
            color: $secondaryColor;
            font-size: 14px;
            margin-top: 16px;
            .bold {
                font-weight: bold;
            }
        }
    }

    .separator{
        border-color: #E8E8E8;
        margin: 32px 0;
    }

}