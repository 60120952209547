@import 'constants.scss';

.notFoundContainer{
    margin: auto;
    padding-bottom: 9%;

    .icon{
        color: #EBEBEB;
        margin-bottom: 8px;
    }

    .titleLarge{
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5em;
        color: #C4C4C4;
        margin-bottom: 24px;
    }
    .titleSmall{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5em;
        color: $textColor; 
        margin-bottom: 16px 
    }
    
}