@import 'constants.scss';


.popperContainer {
    z-index: 20;
}

.datePickerContainer {

    .label {
        font-size: 12px;
        line-height: 21px;
        color: $formElementLabel;

        .required{
            color: $requiredFieldColor;
            font-size: 14px;
        }
    }

    .inputRoot {
        border-radius: 6px;
        height: 40px;
        padding: 7.5px 4px 7.5px 6px;
        font-size: 14px;
        transition: background-color .2s linear;
        border: 1px solid transparent;
        background: $formElementBackground;
        
        &::after, &::before {
            border: none !important;
        }

        &:hover{
            background: #E8E8E8;
        }

        &.Mui-focused {
            background: #fff;
            border-color: $primaryColor;
        }

        .endAdornment {
            position: absolute;
            right: 5px;
            color: rgba(0, 0, 0, 0.54)
        }
        .startAdornment {
            margin-right: 15px;
        }
        .react-datepicker__triangle {
            visibility: hidden;
        }
    }

    .react-datepicker__close-icon{
        padding: 0;
        position: absolute;
        right: 10px;

        &:after{
            background-color: #5A5A5A;
            padding: 0px;
            font-size: 16px;
            border-radius: 50%;
        }
    }

    .disabled{
        align-items: center;
        height: 44px;
        padding: 12px;
        font-size: 14px;
        background: $formElementBackground;
        border-radius: 6px;
    }

    .react-datepicker{
        display: flex;
        box-shadow: 0px 0px 4px 4px rgba(196, 196, 196, 0.25);
        border: none;
        border-radius: 6px;
        padding: 16px 4px;

        .picker{
            position: relative;
            grid-column-gap: 2px;

            .react-datepicker__header{
                background: transparent;
                border-bottom: none;
                padding: 0 0 4px;
                .react-datepicker__current-month{
                    font: 700 14px/20px $fontFamily;
                    color: $primaryColor;
                }
            }

            .react-datepicker__day--today{
                outline: 1px solid $primaryColor;
                border-radius: 0.3rem;
                font-weight: 500;
                color: $textColor;
                background: transparent;
            }

            .react-datepicker__month{
                .react-datepicker__day--selecting-range-start{
                    background: $primaryColor;
                    color: #fff;
                }
            }

            .react-datepicker__day--outside-month{
                outline: none;
            }

            .react-datepicker__day--keyboard-selected, 
            .react-datepicker__month-text--keyboard-selected, 
            .react-datepicker__quarter-text--keyboard-selected, 
            .react-datepicker__year-text--keyboard-selected{
                background: inherit;
                color: inherit;
            }

            .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, 
            .react-datepicker__day--in-range, .react-datepicker__month-text--selected,
            .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range,
            .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected,
            .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
                background: #B3CDCF;
                color: $textColor;
            }

            .react-datepicker__year-text--keyboard-selected,
            .react-datepicker__day--range-end,
            .react-datepicker__day--range-start,
            .react-datepicker__day--selected
            {
                background: $primaryColor;
                color: #fff;
             }

             .react-datepicker__day--keyboard-selected{
                &:focus-visible{
                    outline: none;
                }
             }

            .react-datepicker__navigation-icon{
                &:before{
                    border-color: #5A5A5A;
                    border-width: 1px 1px 0 0;
                    top: 0;
                }
            }
        }
    
        .pickerAction{
            flex-direction: column;
            width: 128px;
            max-height: 245px;
            overflow: auto;
            border-right: 1px solid #C4C4C4;
            padding-right: 4px;

            .button{
                padding: 6px 12px;
                font-size: 14px;
                line-height: 21px;
                border-radius: 6px;
                color: $textColor;
                margin-bottom: 2px;
                justify-content: flex-start;
                font-weight: 600;
                text-align: left;

                &:hover{
                    background: #EBEBEB;
                }
                &.isSelected{
                    background: $primaryColor;
                    color: #fff;
                }
            }
        }
    }

    .buttonType{
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        color: $textColor;

        &.isSelected{
            color: $primaryColor;
        }

        svg{
            font-size: 18px;
            margin-right: 6px;
        }
    }

    .error {
        font-size: 12px;
        color: $fieldErrorColor;
    }

}