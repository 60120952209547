@import 'constants.scss';


    .headerContainer {
        padding: 32px 38px 16px;


        .headerTitle {
            font-size: 24px;
            color: $secondaryColor;
            font-weight: 600;
            min-height: 40px;
            align-items: center;
        }

        .headerTitleSmall{
            align-self: center;
            color: #828282;
            font-size: 20px;

            .headerTitleLine{
                margin: 0 12px;
            }
        }

        .headerInfoTop{
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 24px;
        }

        .headerInfo{
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
        }

        .createButton {
            margin-left: 16px;
            font-size: 16px;
            box-shadow: none;
            height: 36px;

            svg{
                font-size: 16px;
                margin-left: 8px;
            }
        }

        .createButtonText{
            min-height: 36px;
            font-size: 14px;
            font-weight: 600;

            svg{
                font-size: 16px;
                margin-left: 8px;
            }
        }

        .searchInput{
            margin-right: 12px;
        }
        .filterButton {
            margin-right: 8px;
        }

        .resetButtonContainer{
            height: 32px;
            border-left: 1px solid #C4C4C4;

            .resetButton{
                min-width: auto;
                color: #5A5A5A;
                font-weight: 600;

                &:hover{
                    text-decoration: underline;
                    color: #828282;
                    background: none;
                }
            }
        }


    }
