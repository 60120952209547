@import 'constants.scss';

.productHead{
    padding: 32px 38px 0;
}



.infoTopBox{
    justify-content: space-between;
    align-items: flex-start;
    .actionMenu{
        align-self: center;
    }
    .infoTitleBox{
        max-width: 920px;
        font-size: 24px; 
        margin-left: -8px;
        
        .infoTitleInput{
            color: $secondaryColor;
            font-size: 24px;
            
            ::placeholder {
                font-size: 24px;
            }
        }
    }
}
.infoBox{
    width: 680px;
    padding: 0 38px;
}
.info{
    margin-bottom: 24px;
}

.tabs{
    padding: 24px 40px 32px;
}

.initiativesBox{
    height: 100%;
    align-items: flex-start;
}
.objectiveItem{
    white-space: nowrap;
    .objectiveItemCount{
        color: $primaryColor;
        margin-left: 6px;
        font-weight: 600;
    }
}

.teamItem{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    font-size: 12px;
    margin-right: 8px;
    border-radius: 100%;
    color: $white;
    background: $primaryColor;
}

.tableHead{
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 38px;
    margin-bottom: 30px;
}
.tableHeadLeft{
    align-items: flex-start;
    min-width: 430px;
    .searchField{
        margin-right: 12px;
    }
    .select{
        flex-basis: 120px;
    }
}
.tableHeadRight{
    .createButton{
        font-size: 14px;
        font-weight: 600;
        svg{
            font-size: 16px;
            margin-left: 8px;
        }
    }
}

.productList{
    height: 100%;
    align-items: flex-start;
}