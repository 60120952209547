@import 'constants.scss';

.mainContainer {

    .tabs{
        padding: 24px 40px 32px;
    }
    .tabPanel{
        padding: 0 30px;
    }

    .progressContainer {
        margin-right: 30px;

        .progress {
            width: 100px;
            margin-right: 24px;
        }
        .title {
            font-size: 24px;
            color: $secondaryColor;
        }
    }
    
    .infoContainer {
        padding: 32px 38px 0px;

        .infoTopBox{
            justify-content: space-between;
            align-items: flex-start;
            .actionMenu{
                align-self: center;
            }

            .infoRight{
                margin-top: 9px;
            }
            .infoTitleBox{
                max-width: 920px;
                font-size: 24px; 
                margin-left: -8px;
                .titleInput{
                    color: $secondaryColor;
                    font-size: 24px;

                    ::placeholder {
                        font-size: 24px;
                    }
                }
                .infoTitleInput{
                    justify-content: center;
                }
            }
        }
    }

    .infoBox{
        width: 620px;
        margin: 0 38px;

        .info{
            margin-bottom: 24px;
        }
    }

    .contentContainer {    
        .tableHead{
            align-items: flex-start;
            padding: 0 38px;
            margin-bottom: 30px;
            justify-content: space-between;
        }

        .tableContainer {

            .newKrValue{
                padding: 0 7px;
            }

            .selectUnitValue{
                :global{
                    .disabled{
                        background: none;
                        padding: 7px 0;
                        min-height: auto;
                    }
                }
            }
        }
    
        .iconsContainer {
            color: #828282;
            .icon {
                cursor: pointer;
                margin-right: 21px;
            }   
        }
    }    
}
