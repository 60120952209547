@import 'constants.scss';



.container{
    .button{
        height: 32px;
        background: #EBEBEB;
        color: $textColor;
        font-weight: 600;
        box-shadow: none;
        border-radius: 6px;
        padding: 0 12px;
        white-space: nowrap;
    
        &:hover{
            background: #C4C4C4;
            color: $textColor;
            box-shadow: none;
        }
    
        &.active{
            background: $primaryColor;
            color: #fff;
        }
    }
}


.menu{
    .menuItem{
        font-size: 14px;
        padding: 4px 10px;
        margin-bottom: 2px;

        .menuItem-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .checkbox{
            padding: 0;
            svg{
                font-size: 15px;
                margin-right: 6px;
            }
        }
    }

    .filterResetContainer{
        padding: 8px 10px;
        border-top: 1px solid #EBEBEB;

        position: sticky;
        bottom: 0;
        background: #fff;
      
        .filterResetButton{
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #5A5A5A;
            padding: 0;

            &:hover{
                text-decoration: underline;
                background: none;
                color: #828282;
            }
        }

        :global{
            .Mui-disabled{
                color: #C4C4C4;
            }
        }
    }

    .menuPaper{
        max-height: 369px;
        max-width: 400px;
    }

    .searchField {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 1;
        padding-right: 0;
    }

    .searchInput {
        padding-top: 15px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .searchIcon {
        padding-right: 12px;
        width: 20px;
        height: 20px;
    }
}

.menuReset{
    padding-bottom: 0;
    padding-top: 0;
    .empty {
        text-align: center;
        padding: 16px 8px;
        font-size: 14px;
    }
}

.filterOption{
    .filterGroupTitle{
        border-top: 1px dashed #EBEBEB;
        padding: 6px 10px 3px;
        font-size: 12px;
        color: #828282;
        text-transform: uppercase;
    }
}