@import 'constants.scss';

.searchField {
    height: 32px;
    font-weight: 600;
    font-size: 14px;
    color: $textColor;
    border-radius: 6px;
    background-color: #EBEBEB;
    width: 260px;
    border: 1px solid transparent;

    &:hover {
        background-color: #C4C4C4;
    }

    &.focused {
        // width: 433px;
        border-color: $primaryColor;
        background-color: #fff;
    }

    &::before {
        border-bottom: none !important;
    }

    &::after {
        border-bottom: none !important;
    }

    &:hover:not(.Mui-disabled) {
        &::before {
            border-bottom: none !important;
        }
    }

    &.fullWidth {
        width: 100%;
    }

    ::placeholder {
        font-size: 14px;
        color: #828282;
    }

    .searchIcon{
        color: #828282;
    }
}

.input {
    padding-top: 8px !important;
}

.adornedEnd {
    cursor: pointer;
}
