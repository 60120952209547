@import 'constants.scss';


.container{
    padding: 0 38px 32px;
    max-width: 1240px;

    .colLeft{
        flex-basis: 70%;
        border-right: 1px dashed #828282;
        padding-right: 12px;
    }

    .colRight{
        flex-basis: 30%;
        padding-left: 12px;
    }

    .fieldContainer{
        min-width: 240px;
        margin-bottom: 24px;

        &.fieldMaxSize{
            max-width: 330px;
        }

        .requirementTitle{
            :global{
                .disabled{
                    font-weight: 800;
                }
            }
        }

        
        .textarea{
            height: 104px;
            overflow-y: auto;
        }

        .notEditable{
            background: #FAFAFA;
            border-radius: 6px;
            padding: 0 7px;
            min-height: 44px;
            overflow: auto;
            cursor: default;
            margin-top: 8px;
            font-size: 14px;

            &.requirementDescription{
                padding: 7px;
            }
        }

        .fieldLabel{
            font-size: 12px;
            line-height: 21px;
            color: #003539;
        }

        .fieldTitle{
            padding: 10px 12px;
            min-height: 40px;
            background: #FAFAFA;
            border-radius: 6px;

            &.errorField{
                border: 1px solid $fieldErrorColor;
            }
        }

        .groupButtons{
            margin: 8px -9px 0;

            .button{
                color: #5A5A5A;
                border: 1px solid #c4c4c4;
                border-radius: 6px;
                padding: 5.5px 11px;
                margin: 0 9px;
                min-width: 100px;
                font-weight: 700;
                line-height: 1.5em;
                background: #FAFAFA;

                &:hover{
                    background: #c4c4c4;
                }
    
                &.selectedGreen{
                    background: $primaryColor;
                    color: #fff;
                    border-color: $primaryColor;
                }
    
                &.selectedRed{
                    background: #ED4337;
                    color: #fff;
                    border-color: #ED4337;
                }
    
            }
        }

        .errorText{
            font-size: 12px;
            color: $fieldErrorColor;
        }

    }
}

.statusItem{
    padding: 9px 12px 9px 16px;
    box-shadow: 0px 0px 20px 0 rgba(53, 53, 53, 0.14);
    border-radius: 100px;

    .statusItemText{
        font-size: 12px;
        line-height: 18px;
        color: #5A5A5A;
        margin-right: 4px;
    }
}

.actionsMenu{
    margin-left: 17px;
    align-self: center;
}



.fieldInitiativeLink{
    justify-content: space-between;

    >a{
        text-decoration: underline;
        font-size: 14px;
    }

    button{
        padding: 2px;
    }

    svg{
        font-size: 16px;
        color: #5A5A5A;
    }
}

.deletedItemText{
    color: $requiredFieldColor;
    margin-left: 5px;
    font-size: 12px;
    align-items: center;
}