@import 'constants.scss';
 
.actionButtonContainer{

    .actionButton{
        .buttonIcon{
            align-self: baseline;
            margin-right: 7px;

            svg{
                height: 16px;
                width: 16px;
            }
        }
    }

    :global{

        .MuiButton-root{
            margin-left: 24px;
            box-shadow: none;
            height: 36px;
            border-radius: 6px;
            font-weight: 600;
        }

        .MuiButton-contained{
            &:hover{
                background: #003138;
            }
            &.active{
                background: $textColor;
                color: $white;
            }
        }

        .MuiButton-text{
            &:hover,
            &.active{
                background-color: rgba(2, 91, 98, 0.04);
            }
        }

        .MuiButton-outlined{
            font-size: 12px;
            height: 36px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 0 rgba(53, 53, 53, 0.14);
            border-radius: 100px;
            color: #5A5A5A;
            padding: 11px 16px 10px;
            border: none;
            text-wrap: nowrap;
        }

        .MuiButton-endIcon {
            margin-left: 4px;
        }
    }
}

.menu{
    ul{
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .menuItem{
        font-size: 14px;
        padding: 6px;
        color: $secondaryColor;
        font-weight: 600;

        .menuItemIcon{
            margin-right: 6px;
            width: 24px;
            justify-content: center;
        }
    }
    .red{
        color: $fieldErrorColor;
    }
}








