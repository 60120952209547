@import 'constants.scss';
@import 'commonStyles.scss';

.analysisBox {
    width: 100%;
    min-width: 102px;
    padding: 6px 8px;
    gap: 4px;
    border-radius: 200px;
    background-color: $surface1;
    cursor: pointer;

    p {
        font-size: 13px;
    }
}

.analysisItem {
    padding: 16px;
    .analysisItem-subtitle {
        font-size: 16px;
        font-weight: 600;
    }
}

.commonInput-field.upload {
    input {
        cursor: pointer;
    }
}

.commonInput {
    padding: 6px 12px 6px 8px;
    border-radius: 12px;
    background: $surface1;

    :global(.Mui-disabled ) {
        opacity: 0.5;
    }

    :global(.Mui-disabled) {
        color: $secondaryColor;
    }

    :global(.Mui-focused) {
        color: $primaryTextColor;
    }
    
    :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
    }

    :global(.MuiInputBase-input) {
        padding: 8px 0px;
    }
}

.addSourceContainer {
    margin-top: 30px;
    padding: 0px 8px
}

.statusBox {
    gap: 8px;
    font-size: 14px;
    color: $secondaryColor;
    margin: 14px 0px;

    span {
        font-weight: 500;
    }

    .statusBox-info {
        gap: 2px;
    }

    .statusBox-description {
        font-size: 12px;
    }
}