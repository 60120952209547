.tableContainer {
    width: calc(100wh - 200px);
    height: 500px;
    overflow: auto;

    .valueField {
        width: 300px;
        overflow: unset;
        // overflow: auto;
        // height: 100%;
        // align-items: flex-start;

        &.description {
            display: block;
        }
    }
    :global {

        .highLight { 
            background-color: yellow; 
        } 
    }
}
