@import 'constants.scss';


.button{
    height: 32px;
    background: #EBEBEB;
    color: $textColor;
    font-weight: 600;
    box-shadow: none;
    border-radius: 6px;
    padding: 0 12px;

    &:hover{
        background: #C4C4C4;
        color: $textColor;
        box-shadow: none;
    }

    &.active{
        background: $primaryColor;
        color: #fff;
    }
}