@import 'constants.scss';
@import 'commonStyles.scss';

.prdSidePanel {
    height: calc(100vh - 40px);
    overflow: hidden;
    font-size: 16px;

    .prdSidePanel-mainContent {
        height: calc(100vh - 88px);
        overflow: auto;
    }

    .gradingTitle {
        font-size: 18px;
        line-height: 21px;
    }

    .gradingValue {
        font-size: 24px;
        line-height: 48px;
    }

    .titleContainer {
        align-items: center;
        justify-content: center;
        gap: 12px;
        font-weight: 600;
        width: 90%;
    }

    .scoreBox {
        height: 30px;
        width: 55px;
        border-radius: 6px;
    }


    .bgMediumRed {
        background-color: $mediumRed;
    }

    .bgDarkRed {
        background-color: $darkRed;
    }

    .bgLightRed {
        background-color: $lightRed;
    }

    .missingScoresBox {
        border-bottom: 2px solid $surface2;
    }

    .insightTitle {
        font-size: 18px;
        font-weight: 500;
        color: $primaryColor;
    }

    .feedbackTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .subsectionTitle {
        font-size: 16px;
        font-weight: 600;
        color: $primaryColor;
    }

    .subsectionsContainer {
        padding-left: 12px;
        border-left: 2px solid $primaryColor;
    }

    .gradSuggestion {
        border-radius: 8px;
        background-color: #24baa117;
        padding: 12px 22px
    }

    .prdSidePanel-description {
        font-size: 12px;
        color: $secondaryTextcolor;
        align-self: flex-end;
    }
}