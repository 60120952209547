@import 'constants.scss';

.mainContainer {
    height: 100%;
    
    .buttonContainer {
        justify-content: end;

    }
    .tableContainer {
        width: 100%;
        height: 100%
    }
}