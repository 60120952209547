@import 'constants.scss';
 
.actionsMenu{
    .iconButton {
        border-radius: 6px;
        padding: 2px;
        color: #5a5a5a;
        > svg{
            font-size: 1.25rem;
        }
        &:hover{
            background: #c4c4c4;
        }
    }

    .active{
        background: $textColor;
        color: $white;
    }
    
}

.menu{
    ul{
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .menuItem{
        font-size: 14px;
        padding: 6px 12px;

        .menuItemIcon{
            margin-right: 6px;
            >svg{
                font-size: 14px;
            }
        }

        .menuItemLoader{
            margin-left: 5px;
        }
    }
    .red{
        color: $fieldErrorColor;
    }
}








