$headerHeight: 0px;
$mainColor: #191919;
$primaryColor: #025B62;
$primaryColorDark: #003539;
$listItemHoverBackground: rgba(229, 229, 229, 0.2);
$listItemActiveBackground: rgba(229, 229, 229, 0.4);
$formElementBackground: #FAFAFA;
$formElementLabel: $primaryColorDark;
$secondaryColor: #353535;
$white: #fff;
$requiredFieldColor: #ED4337;
$fieldErrorColor: $requiredFieldColor;
$textColor: #191919;
$mediumGreen: #A5D6A7;
$darkGreen: #66BB6A;
$lightYellow: #FFF9C4;
$mediumYellow: #FFF176;
$darkYellow: #FFEE58;
$lightOrange: #FFE0B2;
$mediumOrange: #FFB74D;
$darkOrange: #FF9800;
$lightRed: #FFCDD2;
$mediumRed: #E57373;
$darkRed: #EF5350;
$darkestRed: #C62828;
$red: #CC3535;
$scoreGreen: #C7F7C9;
$scoreRed: #D76158;

$linkColor: #5E7DEA;

$fontFamily: "DM Sans", "Quicksand", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

$secondaryTextcolor: #666666;
$primaryTextColor: #303030;
$accentCaution: #FF9429;
$accentError: #FF7986;
$accentSuccess: #33CCB2;
$surface2: #F0F0F0;
$surface1: #F7F7F8;
$accentCritical: #b61928;
$accentDisabled: #666666;
$brandPrimaryColor: #009965;
$surfacesBackgroundColor: #FAFAFA;
$accentLink: #4C3DF5;
$textOnPrimary: #D9F2EA;
$brandGreen100: #008558;

$longShadow: 0px 2px 13px 0px rgba(109, 109, 141, 0.12);
$widget: -8px 2px 20px 0px rgba(109, 109, 141, 0.06);
$buttonShadow: 0px -3px 0px 0px #F5F5FA inset, 0px 1px 6px 0px rgba(109, 109, 141, 0.12);
$simpleButtonShadow:  0px 2px 8px -4px rgba(109, 109, 141, 0.07);
$softShadow: 0px 1px 6px -2px rgba(140, 130, 154, 0.14);