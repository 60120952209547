@import 'constants.scss';

.table {


    .MuiTableCell-root{
        border-bottom: none;
    }

    &.nested {
        padding-left: 30px;
        margin-top: 8px;

        .tableHead{
            .MuiTableRow-head{
                .MuiTableCell-head{
                    border-bottom: none;
                }
            }

            
        }

        tbody{
            .tableRow {
                &:nth-child(even) {
                    background-color: #F8F8F8;
                }

                &:not(:last-child) .cell:after{
                    content: "";
                    height: 2px;
                    background: #fff;
                    width: calc(100% - 2px);
                    display: block;
                    position: absolute;
                    left: 1px;
                    right: 1px;
                    bottom: -1px;
                    z-index: 1;
                }

                td{
                    border-bottom: none;
                }
            }
        }
            
        .MuiTableBody-root{
            border-left: 1px solid $primaryColor;
            border-right: 1px solid $primaryColor;
        }

    }

    .tableHead {
        .MuiTableRow-head{
            .MuiTableCell-head{
                font-size: 14px;
                font-weight: 600;
                padding: 5px 10px;
                border-bottom: 1px solid #c4c4c4;
                z-index: 15;
                color: #828282;
                &:first-child{
                    padding-left: 38px;
                }

                &:last-child{
                    padding-right: 38px;
                }

            }
        }

        .MuiTableSortLabel-root {
            color: #828282;
            &:hover {
                color: inherit;

                .MuiSvgIcon-root {

                    opacity: 1;
                }
            }
            &.Mui-active {
                .MuiSvgIcon-root {
                    color: #828282;
                }
            }
            .MuiSvgIcon-root {
                opacity: 1;
            }
        }
    }

    .tableRow {
        height: 48px;
        transition: background-color .2s linear;
        position: relative;

        &.odd{
            background: #F8F8F8;
        }

        td{
            border-bottom: 1px solid #dddddd;
            
        }
        &:last-child {
            td {
                border-bottom: none;
            }
        }

        &.clickable {
            cursor: pointer;

            &:hover {
                background: #EBEBEB;
            }
        }
        

        &.isOpen{
            background: #EDEDED;
        }

        &.hasEditableField{
            background: #fff;
            td{
                border-bottom: 1px solid #C4C4C4;
            }
        }

        .arrowIcon{
            cursor: pointer;
        }

        .cell {
            vertical-align: middle;
            padding: 0;

            &.cellStandard {
                padding: 5px 10px;
                box-sizing: border-box;
            }

            .cellLink {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                color: inherit;
                text-decoration: none;
                padding: 5px 10px;
                box-sizing: border-box;
            }

            &:first-child{
                .cellLink,
                &.cellStandard {
                    padding-left: 38px;
                }
            }

            &:last-child{
                .cellLink,
                &.cellStandard  {
                    padding-right: 38px;
                }
            }
        }
        
    }
}

.emptyState{
    font-size: 24px;
    color: #C4C4C4;
    font-weight: 600;
    margin-top: 140px;
    justify-content: center;
}