@import 'commonStyles.scss';
@import 'constants.scss';

.snackbarContainer {
    width: 333px;
    border-radius: 20px;
    background-color: #fff;

    &.bottomLeft {
        left: 244px
    }

    & :global(.MuiAlert-root) {
        width: inherit;
        display: flex;
        padding: 16px 12px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 20px;

        &.error {
            border: 1px solid $accentError;
            background: rgba($accentError, 0.10);

            .errorSvg {
                background-color: $accentError;
                border-radius: 100%;
            }
        }

        &.warning {
            border: 1px solid $accentCaution;
            background: rgba($accentCaution, 0.20);
        }

        &.success {
            border: 1px solid $accentSuccess;
            background: rgba($accentSuccess, 0.20);
        }
    }

    & :global(.MuiAlert-icon) {
        margin-right: 0;
        padding: 0;
        width: 24px;
        height: 24px;
    }

    & :global(.MuiAlert-message) {
        width: 100%;
        display: flex;
        padding: 0;
        justify-content: space-between;
    }

    .snackbar-textcontent {
        gap: 2px
    }

    .snackbar-textcontent--description {
        font-size: 15px;
    }

    .closeButton {
        &:global(.MuiIconButton-root) {
            padding: 0;
            display: flex;
            align-items: start;
            justify-content: center;
            height: fit-content;
            color: $primaryTextColor;

            &:hover {
                background-color: inherit;
            }
        }
    }
}