@import 'constants.scss';

.mainContainer {
    height: 100%;

    .tableContainer{
        height: 100%;
        align-items: flex-start;

        .placeholderColumn{
            color: #828282;
        }
    }

    .datePickerBox{
        align-self: center;
    }

    .progressContainer {
        .progress {
            width: 100px;
            margin-right: 24px;
        }
        .title {
            font-size: 24px;
            color: $secondaryColor;
        }
    }
}
