.drawerContainer {
    width: 472px;
    padding: 20px;
    height: 100%;
}

.sidePanelDrawer {
    .prdScoreDrawer {
        border-radius: 20px 0px 0px 20px !important;
    }
}