@import 'constants.scss';


.imageViewer{
    position: absolute;
    z-index: 10000;

    .react-viewer-close{
        border-radius: 0;
        width: 34px;
        height: 34px;
        i{
            top: 6px;
            left: 11px;
        }
    }

    .react-viewer-toolbar{
        .react-viewer-btn{
            .react-viewer-icon{
                color: #fff;
                border-radius: 0; 
                margin-right: 0;
            }
        }
    }
}
