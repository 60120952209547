@import 'constants.scss';
@import 'commonStyles.scss';

.kbSidebarContainer {
    height: calc(100vh - 40px);
    overflow: hidden;

    :global(.MuiAvatar-root) {
        width: 24px;
        height: 24px;
        font-size: 12px;
        font-weight: 900;
        line-height: 20px;
    }

    :global(.MuiTypography-root) {
        font-size: 15px;
    }

    .kbSidebar-mainContent {
        height: calc(100vh - 101px);
        overflow: auto;
    }

    .kbSidebar-titleContainer {
        border-bottom: 1px solid $surface1;
    }
   
    .kbSidebar-group {
        border-radius: 16px;
        border: 1px solid  $surface2;
        padding: 16px;
    }

    .kbSidebar-groupItem {
        border-bottom: 1px solid $surface1;
        &.last {
            border-bottom: none;
        }
        &.group2:last-child {
            border-bottom: none;
        }
    }

    .kbSidebar-user {
        padding: 6px;
    }

    .kbSidebar-status {
        padding: 6px 8px;
        border-radius: 200px;
        text-transform: capitalize;
    }

    .kbSidebar-link {
        border-radius: 8px;
        background: $surface1;
        padding: 2px 8px;
        gap: 4px;

        :global(.MuiTypography-root) {
            font-size: 16px;
        }

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .kbSidebar-subtitle {
        font-size: 16px;
        font-weight: 500;
    }

    .kbSidebar-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    .kbSidebar-group2Item {
        padding: 6px;
        :global(.MuiAvatar-root){
            background-color: #B4A3E4;
        }
    }
}