@import 'constants.scss';


.addButtonContainer{
    height: 36px;

    .addButton {
        min-width: auto;
        font-size: 14px;
        font-weight: 600;
        color: $primaryColor;
        padding: 4px 8px;
        border-radius: 6px;
    
        &:hover {
            background: $primaryColor;
            color: #fff;
        }
    
        &.active{
            background: #42888F;
            color: #fff;
            &:hover{
                background: $primaryColor;
                color: #fff;
            }
        }
    
    }
}

