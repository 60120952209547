@import 'constants.scss';


.container{
    .button{
        height: 32px;
        background: #EBEBEB;
        color: $textColor;
        font-weight: 600;
        box-shadow: none;
        border-radius: 6px;
        padding: 0 12px;
        white-space: nowrap;
    
        &:hover{
            background: #C4C4C4;
            color: $textColor;
            box-shadow: none;
        }
    
        &.active{
            background: $primaryColor;
            color: #fff;
        }

        &.iconButton{
            height: 36px;
            min-width: auto;
            padding: 0 9px;
            margin-left: 12px;

            :global{
                .MuiButton-startIcon{
                    margin: 0;
                }
                svg{
                    font-size: 18px;
                }
            }
        }

    }
}

.menu {
    min-width: 315px;
    padding: 16px 24px 40px 24px;
    box-sizing: border-box;
    

    .mainTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        color: $textColor;
        margin-bottom: 16px;
    }
    .groupContainer {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0px;
        }

        .groupTitle {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #5A5A5A;
        }
    
        .menuItem{
            font-size: 14px;
            padding-left: 5px;
            padding-right: 10px;
            .checkbox{
                padding: 3px;
            }
        }
    }

    :global {
        .Mui-selected{
            background: none;
            &:hover{
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }  
}