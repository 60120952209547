@import 'constants.scss';

.snackbarContainer{
    
    
    &.error{
        background-color: #FFEBE6;
    }
    &.warning{
       background-color: #FFFAE6;
    }
    &.success{
        background-color: #E3FCEF;
        
    }
    &.bottomLeft {
        left: 244px
    }

    .successIcon {
        color: $primaryColor
    }

    .warningIcon {
        color: #FF8B00
    }

    .errorIcon {
        color: $fieldErrorColor;
    }

    

}
