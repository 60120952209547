.switchContainer {
    box-shadow: 0px 0px 4px 4px rgba(196, 196, 196, 0.25);
    border-radius: 6px;
    overflow: hidden;

    .switchItem {
        padding: 9px 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background: #fff;
        border-right: 1px solid #EBEBEB;

        &:last-of-type {
            border-right: none;
        }
        
        &.selected {
            background-color: #EBEBEB;
        } 
    }
}