@import 'constants.scss';

.comment-header {
  display: flex;
  align-items: center;
  gap: 8px;

  .avatar {
    background-color: $primaryColor;
    width: 24px;
    height: 24px;
    font-size: 14px;
    flex-shrink: 0;
    font-weight: 500;
  }

  .time-ago {
    font-size: 14px;
    color: #828282;
    font-weight: 500;
    flex-shrink: 0;
  }
}