@import 'constants.scss';
@import 'commonStyles.scss';

.customTypography {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:global(.MuiTypography-caption) {
        font-size: 13px;
        font-weight: 500;
    }
}

.secondary {
    color: $secondaryTextcolor;
}

.primary {
    color: $primaryTextColor;
}