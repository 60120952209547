
.tableContainer{
    margin-bottom: 56px;

    .title{
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #5A5A5A;
        margin: 0 38px 18px;
    }

    .progressContainer {
        width: 100px;
        margin-right: 12px;
    }

    :global{
        .emptyState{
            font-size: 18px;
            margin-top: 30px;
        }
    }

}