.comments-sidebar-header {
    box-shadow: 0px 4px 4px 0px rgba(196, 196, 196, 0.25);
    flex: 0 0 auto;

    heading {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
    }
}