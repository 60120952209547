@import 'constants.scss';

.inviteDialogContainer{

    :global{
        .MuiDialog-paper{
            .MuiDialogContent-root{
                padding-left: 0;
                padding-right: 0;
                width: 566px;
                overflow-x: hidden;
            }
        }
    }

    .invitePopupMainContainer{
        margin: 0 -22px;

        .iconButton{
            > svg{
                font-size: 1.15rem;
            }
        }
    }
    
}

