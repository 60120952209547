.dialogContainer{
    width: 630px;
    min-height: 166px;


    .dialogOneCol{
        position: relative;
        margin-bottom: 24px;

        .switchContainer{
            position: absolute;
            right: 0;
            top: -3px;
        }
    }

    .dialogTwoCol{
        grid-column-gap: 24px;
        margin-bottom: 24px;
    }
}