@import 'constants.scss';
@import 'commonStyles.scss';

.alertsSidePanel {
    .alertsSidePanel-keyNumber {
        color: #3E3E3E;
    }

    .alertsSidePanel-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    .alertsSidePanel-tabsContainer {
        padding: 2px;
        gap: 2px;
        border-radius: 8px;
        background: $surface1;

        & :global(.MuiTabs-root) {
            min-height: fit-content;
        }

        .alertsSidePanel-tabs {
            width: 100%;

            & :global(.MuiTabs-flexContainer) {
                gap: 2px;
                color: $primaryTextColor;
            }

            & :global(.MuiButtonBase-root) {
                padding: 8px 12px;
                min-height: inherit;
                flex: 1;
                font-family: "DM Sans";
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: $primaryTextColor;
                opacity: 0.7;

            }

            & :global(.Mui-selected) {
                background-color: #fff;
                box-shadow: $buttonShadow;
                border-radius: 8px;
                color: #303030;
                font-weight: 500;
                opacity: 1;
            }

            & :global(.MuiTabs-indicator) {
                display: none;
            }
        }
    }

    .alertsSidePanel-tabTitle {
        font-style: normal;
        font-weight: 500;
    }

    .alertsSidePanel-description {
        font-size: 12px;
        color: $secondaryTextcolor;
    }

    .alertsSidePanel-task {
        background-color: #F7F7F8;
        border-radius: 12px;

        // .alertsSidePanel-taskNumber {
        //     color: #9C9C9C;
        //     font-weight: 500;
        //     font-size: 13px;
        // }

        .alertsSidePanel-taskTitle {
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // max-width: 350px;
            font-weight: 600;
        }
    }
}