.comment-item {
    width: 100%;

    .header {
        padding: 0;
    }

    .message {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
    }
}
