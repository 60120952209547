
@import 'constants.scss';

.head{
    margin-bottom: 8px;

    .title{
        font-size: 12px;
        margin-right: 4px;
        cursor: pointer;
    }

    .iconContainer{
        width: 20px;
        cursor: pointer;

        svg{
            margin: auto;
        }
    }
}

.separator{
    margin: 12px 0;
    border-color: #EBEBEB;
}

.linkContainer{
    margin: 4px 0;
    min-height: 24px;

    .linkText{
        display: inline-flex;
        font-size: 14px;
        font-weight: 600;
        margin-right: 4px;
        align-self: center;
        color: $linkColor;
        text-decoration: none;
        word-break: break-all;
    }

    .disabled-link {
        color: gray; 
        pointer-events: none;
        text-decoration: none;
    }

    .linkActionsMenu{
        visibility: hidden;

        svg{
            width: 18px;
            height: 18px;
        }
    }

    &:hover{
        .linkActionsMenu{
            visibility: visible;
        }     
    }
  
}

.addNewButton{
    font-size: 12px;
    font-weight: 700;
    margin-top: 4px;

    :global{
        .MuiButton-startIcon{
            margin-right: 4px;

            *:nth-of-type(1){
                font-size: 16px;
            }
        }
    }

}

.popoverContainer{
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(53, 53, 53, 0.24);
    padding: 24px;
    width: 448px;
}

.input{
    margin-bottom: 16px;
}

.buttonsContainer{
    margin-top: 16px;

    .button{
        box-shadow: none;
        margin-left: 15px;
        padding: 5px 10px;
        border-radius: 6px;

        .buttonLoader{
            margin-left: 5px;
        }
    }
}

.attachmentContainer {
    gap: 8px;
    align-items: center;
    .actionIcon {
        width: 25px;
        height: 25px;
        color: #5a5a5a;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .uploadProgress {
        width: 40% ;
    }
}