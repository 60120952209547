@import 'constants.scss';

.wireframeTabContainer{
    padding: 0 38px;
    flex-wrap: wrap;

    .wireframeListContainer {
        flex-flow: row wrap;
        gap: 10px;
        height: 100%;
        .wireframeContainer{
            width: 528px;
            height: 290px;
            max-width: 1200px;
            border: 2px solid $listItemActiveBackground;
            .wireframeTop{
                justify-content: space-between;
                height: 48px;
                background: #FAFAFA;
                padding: 0 24px;
                .iconButton{
                    color: #5A5A5A;
                }

                .actionsButtonContainer {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    
        .iframeContainer{
            // position: relative;
            // padding-bottom: 100%;
            overflow: hidden;
            // background: #FAFAFA;
            border-radius: 6px;
            cursor: zoom-in;
            
            &.newButtonContainer {
                font-size: 16px;

                .newButton {
                    font-weight: bold;
                }
            }
            .iframeItem{
                border: none;
                overflow: hidden;
                align-self: center;
                // position: absolute;
                top: 0;
                left: 0;
                width: 200%;
                height: 484px;
                transform: scale(0.5);
                pointer-events: none;
            }
        }
    
    }

}

.refineDialog{
    width: 446px;
}


.maximizeDialogComponentRoot {
    .maximizeDialogComponentPaper{
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 6px;

        max-width: none;
        .titleContainer {
            padding: 0 20px;
            width: 860px; 
            height: 48px;
            background-color: #FAFAFA;
            box-shadow: 0px 1px 4px 0px #3535353D;
            justify-content: space-between;

            .iconsContainer {
                width: 24px;
                height: 24px;
            }
        }

        .dialogContent{
            padding: 0;
            font-size: 14px;
            line-height: 1.4em;
            font-weight: 500;
        }
    
       
    
    }
}