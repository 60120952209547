@import 'constants.scss';

.selectStandard {

    .select {
        color: #5A5A5A;
        padding: 11px 16px 10px;
    }
    .selectBase {
        font-size: 12px;
        height: 36px;
        background-color: #fff;
        box-shadow: 0px 0px 20px 0 rgba(53, 53, 53, 0.14);
        border-radius: 100px;
 

        &.Mui-focused {
            background-color: #fff;
            border-color: $primaryColor;
        }

        .MuiSelect-select{
              background-color: #fff;
              border-radius: 100px;
        }
    
        &::after, &::before {
            border: none !important;
        }

        &.Mui-disabled{
            background: #f5f5f5;
        }
    }
    &.closeFocus{
        .selectBase{
            &.Mui-focused {
                background-color: #F5F5F5;
                border-color: transparent;
            }
        }
    }
}
