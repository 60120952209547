@import 'constants.scss';

.editor {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 2px;
    background: #fefefe;

    &.readonly {
        padding: 0;
        background: none;
    }
}

// without this the editor's caret is not visible in reply mode
:global(.DraftEditor-root) {
    width: 100%;
}

.mention {
    background-color: #edfaf5;
    color: #009243;
    text-decoration: none;
}

.mentionSuggestions {
    border-radius: 8px;
    background: #f8f8f8;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(29, 28, 29, 0.13);
    z-index: 10;
    max-height: 208px;
    min-width: 280px;
    overflow-y: auto;
}

.mentionSuggestionsEntry {
    background: #f8f8f8;
    display: flex;
    padding: 6px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.mentionSuggestionsEntryFocused {
    display: flex;
    padding: 6px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    background-color: $primaryColor;
    color: $white;
    cursor: pointer;
}

.mentionSuggestionsEntryText {
    font-weight: 600;
}

.entry {
    display: flex;
    padding: 0 16px;
    margin: 0;
    cursor: pointer;

    &:hover {
        background-color: #eee;
    }

    &:active {
        background-color: #eee;
    }
}

.entry-title {
    flex: 1;
    font-weight: bold;
    margin: 8px 0;
}

.with-tools {
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    background: #FFF;
    overflow: hidden;
}

.tools {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #C4C4C4;
    padding: 3px 7px;
}