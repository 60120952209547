.comment-filter {
    padding: 4px 16px;
    background-color: #FAFAFA;
}

.comment-filter-menu-item {
    padding: 6px 12px;
}

.comment-filter-radio-button {
    padding: 0;
    margin-right: 8px;
}

.comment-resolved-button {
    padding: 0;
    height: 24px;
    width: 24px;
    color: #5a5a5a;
    > svg {
        font-size: 1rem;
    }
    &:hover {
        background: #c4c4c4;
    }
}
