
@import 'constants.scss';

.attachmentContainer {
    gap: 8px;
    align-items: center;
    .actionIcon {
        width: 25px;
        height: 25px;
        color: #5a5a5a;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .uploadProgress {
        width: 40% ;
    }
}

.linkContainer{
    margin: 4px 0;
    min-height: 24px;

    .linkText{
        display: inline-flex;
        font-size: 14px;
        font-weight: 600;
        margin-right: 4px;
        align-self: center;
        color: $linkColor;
        text-decoration: none;
        word-break: break-all;
    }

    .disabled-link {
        color: gray; 
        pointer-events: none;
        text-decoration: none;
    }

    .linkActionsMenu{
        visibility: hidden;

        svg{
            width: 18px;
            height: 18px;
        }
    }

    &:hover{
        .linkActionsMenu{
            visibility: visible;
        }     
    }
  
}

.separator{
    margin: 12px 0;
    border-color: #EBEBEB;
}