@import 'constants.scss';

.comment-field {
    width: 100%;
    position: relative;

    &.new-comment {
        background-color: inherit;
    }

    &.outlined {
        outline-color: $primaryColor;
    }

    .pin-container {
        top: -16px;
        right: 8px;
        position: absolute;
        z-index: 2;

        &.right-gutter {
            right: 40px;
        }
    }

    .new-comment-button {
        background-color: white;
        position: absolute;
        bottom: -5px;
        right: -5px;
        border-width: 1px;
        border-style: solid;
        border-color: $primaryColor;
        z-index: 1;

        &:hover {
            background-color: white;
        }
    }
}