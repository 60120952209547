@import 'constants.scss';

.comment-avatar {
    width: 24px;
    height: 24px;
    font-size: 12px;
    flex-shrink: 0;
    font-weight: 500;
    background: $secondaryColor;
    
    &.filled {
        background: $primaryColorDark;
    }
}
