@import 'constants.scss';

.comment-source-container {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #828282;
    position: relative;
    height: 24px;
}

.comment-thread-container {
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #c4c4c4;
    padding: 16px 24px;
    box-sizing: border-box;
    caret: unset;
    cursor: pointer;

    &:hover {
        background-color: #fafafa;
    }

    &.highlighted {
        background-color: #eeeeee;
    }

    &.selected {
        cursor: unset;
    }
}

.comment-show-less-button {
    color: $primaryColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    
    &:hover {
        background: none;
    }
}

.comment-replies-button {
    display: block;
    color: $primaryColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    border: unset;
    background: unset;
    cursor: pointer;
}
