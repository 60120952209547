@import 'constants.scss';
@import 'commonStyles.scss';

.customTabs {
    border-radius: 10px;
    background: $surface1;
    padding: 2px;

    :global(.MuiTabs-flexContainer) {
        gap: 2px;
    }

    :global(.MuiTabs-indicator) {
        display: none;
    }

    &:global(.MuiTabs-root) {
        min-height: fit-content;
        box-sizing: border-box;
    }
    
    .customTab {
        border-radius: 8px;
        opacity: 0.7;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 20px;
        color: $primaryTextColor;
        flex: 1;

        &:global(.Mui-selected) {
            background:  #FFF;
            box-shadow: $buttonShadow;
            font-weight: 500;
            opacity: 1;
        }

        &:global(.Mui-disabled) {
            opacity: 0.3;
        }

        &:global(.MuiTab-root) {
            min-height: fit-content;
        }
    }
}