@import 'commonStyles.scss';
@import 'constants.scss';

.alertsBox {
    width: 94px;
    padding: 6px 8px;
    gap: 4px;
    border-radius: 200px;
    cursor: pointer;

    p {
        font-size: 13px;
    }
}