@import 'constants.scss';
@import 'commonStyles.scss';

.ag-theme-quartz {
    --ag-row-border-width: 1px;
    --ag-row-border-color: #F7F7F8;
    --ag-header-background-color: #fff;
    --ag-header-foreground-color: #2F3B31;
    --ag-font-size: 14px;
    --ag-font-family: $fontFamily;
    --ag-header-cell-font-weight: 600;
    --ag-font-weight: 400;
    --ag-foreground-color: #404040;
}

.ag-header-cell-text {
    font-weight: 600;
}

.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell),
.ag-pinned-right-header,
.ag-pinned-left-header,
.ag-header,
.ag-rtl .ag-side-bar-left,
.ag-ltr .ag-side-bar-right {
    border-color: $surface1;
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-focus:not(.ag-cell-range-right):focus-within):not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right-color: $surface1; 
}

.ag-center-cols-container {
    min-width: 100%;
}

.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport {
    min-height: 50px;
}

.ag-cell {
    display: flex;
    align-items: center;
}

.editor-cell {
    display: block;
    align-items: normal;
}

// TODO: Review
.ag-custom-cell .ag-cell-wrapper {
    .ag-cell-expandable.ag-row-group  {
        line-height: 1.5;
    }
}

.ag-cell-wrapper.ag-row-group {
    align-items: center;

    .ag-group-contracted,
    .ag-group-expanded {
        padding: 4px;
        border-radius: 8px;
        background: $surface1;
        height: 24px;
        width: 24px;

        &:hover {
            background-color: $surface2;
        }

        .ag-icon:hover {
            box-shadow: none;
            border-radius: 8px;

            &:hover {
                background-color: $surface2;
            }
        }
    }
}

.agGrid-container {
    width: 100%;
    height: 100%;

    .ag-root-wrapper {
        border: none;
    }

    .agGrid-table {
        height: 100%;

        .ag-placeholder {
            color: #828282;
            font-style: italic;
        }
    }

    .itemBox {
        height: 100%;
        align-items: center;
        gap: 6px;

        .chipItem {
            word-break: auto-phrase;
            background: #ebebeb;
            padding: 5px 9px;
            border-radius: 100px;
            text-align: center;
            font-size: 13px;
            line-height: 1.5;
            height: fit-content;
        }

        .circleItem {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 24px;
            font-size: 12px;
            border-radius: 100%;
            color: $white;
            background: $primaryColor;
        }
    }

    .progressContainer {
        min-width: 120px;

        .progress {
            width: 100px;
            margin-right: 24px;
        }
    }

    .ag-custom-cell {
        line-height: 1.5;
        padding: 4px 15px;
        display: flex;
        align-items: center;
    }

    .ag-custom-cell.ag-title {
        box-sizing: border-box;
    }

    .ag-custom-cell.chip-cell {
        overflow: hidden;
    }

    .linked-cell {
        display: inline-block;
        height: 100%;
        width: 100%;
        color: $primaryColor;
    }

    .ag-deletedItemText {
        color: $requiredFieldColor;
        font-size: 12px;
        align-items: center;
    }

    .ag-gap-5 {
        gap: 5px;
    }

    .grouped-title {
        line-height: 1.5;
    }

    // _____________________Components___________________
    .agGrid-custom-select {
        background: #fff;
        height: 100%;
        width: 100%;
        border: 1px solid #2196f3;
        border-radius: 6px;

        .inputRoot.MuiAutocomplete-inputRoot {
            padding: 2px 0;
        }

        & .MuiFilledInput-root.Mui-focused {
            border: none;
        }

        & .inputComponentMainContainer .inputRoot .MuiFilledInput-root:hover {
            background-color: inherit;
        }
    }

    .grouped-editor {
        p {
            margin: 0;
        }
    }

    .ag-score-cell {
        display: flex;
        align-items: center;
    }

    .source-cell {
        width: fit-content;
        gap: 4px;
        padding: 2px 8px;
        border-radius: 8px;
        background-color: $surface1;

        &.sources{
            max-width: 160px;
            text-align: start;
        }

        p {
            font-weight: 500;
            line-height: 20px;
        }

        svg {
            width: 18px;
            height: 18px;
        }
  
        :hover {
            background-color: $surface2;
        }
    }
}

.AgExport {
    .AgExport-btn {
        margin-left: 16px;
    }
}

.ag-cell-inline-editing .ag-cell-editor input {
    height: auto;
    border: none;
    &:focus {
        box-shadow: none;
    }
}

.ag-cell-popup-editing {
    visibility: hidden;
    &.ag-cell-focus:not(.ag-cell-range-selected):focus-within{
        border: none;
    }
}