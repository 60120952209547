@import 'constants.scss';
@import 'commonStyles.scss';

.addKnowledgeContainer {
    & :global(.MuiPaper-root) {
        width: 466px;
        padding: 24px;
        gap: 24px;
        border-radius: 24px;

        :global(.MuiDialogTitle-root) {
            padding: 0px;
            padding-bottom: 16px;
            border-bottom: 1px solid $surface1;
        }
    }

    :global(.addKnowledgeContent.MuiDialogContent-root) {
        gap: 24px;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .linkNodeInputBox {
        height: 48px;

        .linkNodeInputBox__left {
            text-align: center;
            border-radius: 12px 0 0px 12px;
            padding: 6px 12px 6px 8px;
            background: $surface2;
            color: #ACACAC;
            font-size: 15px;
            line-height: 20px;
        }

        .linkNodeInputBox__right {
            border-radius: 0px 12px 12px 0px;
            background: $surface1;
            padding: 6px 8px;
        }

        .linkNode-btn {
            background-color: $primaryColor;
        }

        .linkNodeInput {
            width: 80%;

            & :global(.MuiInputBase-root) {
                width: 100%;
                border: none;
                height: 100%;
                box-sizing: border-box;
                padding: 0;
            }

            & :global(.MuiOutlinedInput-notchedOutline) {
                border: none;
            }

            & :global(.MuiOutlinedInput-input) {
                padding: 0;
            }
        }

    }

    .addNewNodeTitle {
        font-size: 16px;
        font-weight: 500;
        color: $primaryTextColor;
    }

    .infoText {
        font-size: 15px;
        color: #404040;
    }

    .uploadKbSource {
        .uploadKbSource-description {
            font-size: 14px;
            color: #757575;
        }
    }

    .linkContainer{
        margin: 4px 0;
        min-height: 24px;
    
        .linkText{
            display: inline-flex;
            font-size: 14px;
            font-weight: 600;
            margin-right: 4px;
            align-self: center;
            color: $linkColor;
            text-decoration: none;
            word-break: break-all;
        }
        
        .linkActionsMenu{
            visibility: hidden;
    
            svg{
                width: 18px;
                height: 18px;
            }
        }
    
        &:hover{
            .linkActionsMenu{
                visibility: visible;
            }     
        }
    }
}


