.container{
    width: 685px;

    .fieldLabel{
        font-size: 12px;
        line-height: 21px;
        color: #003539;
    }

    .fieldTitle{
        padding: 10px 12px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 6px;
        margin-bottom: 16px;
        text-overflow: ellipsis; 
        white-space: nowrap; 
        overflow: hidden;
    }

    .fieldTitleSmall{
        .fieldTitle{
            width: 330px;
        }
    }

    .notEditableTitle{
        font-weight: 700;
        margin-bottom: 8px;
    }

    .notEditableDescription{
        height: auto;
        max-height: 136px;
        min-height: 40px;
        overflow: auto;
        cursor: default;
    }

    .textarea{
        margin-bottom: 16px;
    }

    .twoColBox{
        grid-column-gap: 25px;
    }

}