@import 'constants.scss';
@import 'commonStyles.scss';


:global(.customDialogPaper) {
    min-width: 300px;
    max-width: 538px;
    padding: 20px;
    border-radius: 24px;
    border: 1px solid $surface2;
    background: #FFF;
    box-shadow: 0px 4px 20px -20px rgba(87, 92, 210, 0.10);

    .customDialogTitleContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 0 16px;

        .customDialogTitle {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;

        }

        .customDialogDescription {
            max-width: 480px;
        }

        .customDialogCloseIcon {
            width: 32px;
            height: 32px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .customDialogContent {
        margin-top: 24px;
        padding: 0;
    }

    & :global(.MuiDialogActions-root) {
        padding: 8px 0px;
    }
}