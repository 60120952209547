@import 'constants.scss';

.jiraExportDialogContainer {
    margin-top: 32px;

    .rowContainer{
        margin-bottom: 24px;

        .rowInput{
            :global{
                .Mui-disabled{
                    input{
                        -webkit-text-fill-color: $textColor;
                    }
                }
            }
        }

    }
}

.jiraMappingLink{
    margin-left: 5px;
    color: $linkColor;
}