.mainContainer {
    height: 100%;
    .datePickerBox{
        align-self: center;
    }

    .tableContainer {
        align-items: flex-start;
        width: 100%;
        height: 100%;

        :global{
            .tox-tinymce.tox{
                background-color: transparent;
            }
        }

        .topAlign {
            height: 100%;
            vertical-align: top; 
            padding-top: 14px;

            &.priorityIcon {
                padding-top: 21px;
            }
        }
    }
}