@import 'constants.scss';

.jiraActions{
    margin-right: 15px;

    .jiraActions-btn {
        :global(.MuiButton-root) {
            margin-left: 8px;
        }
    }

    .jiraUrl{
        color: $primaryColor;
        font-size: 16px;
        white-space: nowrap;
    }
}

.jiraActionsItem {
    align-items: center;

    .jiraIcon {
        display: flex;
        align-items: center;
        margin-right: 5px;
        svg{
            height: 16px;
            width: 16px;
        }
    }
}

.menuItem {
    font-size: 14px;
    padding: 4px 10px;
    margin-bottom: 2px;
}


      