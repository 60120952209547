.flexbox {
    display: flex;
    box-sizing: border-box;

    &.vertical {
        flex-direction: column;
    }

    &.align {
        align-items: center;
    }

    &.justify {
        justify-content: center;
    }

    &.justifyEnd {
        justify-content: flex-end;
    }

    &.fullWidth {
        width: 100%;
    }

    &.fullHeight {
        height: 100%;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.justifyBetween {
        justify-content: space-between;
    }

    &.justifyStart {
        justify-content: flex-start;
    }
}