@import 'constants.scss';
@import 'commonStyles.scss';

.linkNodeContainer {
    & :global(.customDialogPaper) {
        width: 538px;
    }

    .linkNode-header {
        font-size: 17px;
        line-height: 24px;
    }

    .linkNodeInputBox {
        height: 48px;


        .linkNodeInputBox__left {
            text-align: center;
            border-radius: 12px 0 0px 12px;
            padding: 6px 12px 6px 8px;
            background: $surface2;
            color: #ACACAC;
            font-size: 15px;
            line-height: 20px;
        }

        .linkNodeInputBox__right {
            border-radius: 0px 12px 12px 0px;
            background: $surface1;
            padding: 6px 8px;
        }


        .linkNodeInput {
            width: 80%;

            & :global(.MuiInputBase-root) {
                width: 100%;
                border: none;
                height: 100%;
                box-sizing: border-box;
                padding: 0;
            }

            & :global(.MuiOutlinedInput-notchedOutline) {
                border: none;
            }

            & :global(.MuiOutlinedInput-input) {
                padding: 0;
            }
        }

    }

    .linkNode-btn {
        height: 36px;
        padding: 8px 20px;
        border-radius: 10px;
        background: #025B62;
        color: #D9F2EA;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;

        &:global(.Mui-disabled) {
            background: $surface2;
            color: $primaryTextColor;
        }

        &:hover {
            background: #003539
        }
    }

    .linkedNode {
        padding: 12px;
        background-color: $surface1;
        border-radius: 12px;
        gap: 8px;

        &:hover {
            background-color: $surface2;
        }
    }
}