.comment-new {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 16px;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    background: #f5f5f5;

    .input-container {
        border-radius: 4px;
        border: 1px solid #C4C4C4;
        background: #FFF;
        overflow: hidden;
    }

    .input {
        padding: 12px;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #C4C4C4;
        padding: 3px 7px;
    }
}
