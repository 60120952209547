.mainContainer {
    .roadMapContainer {
        overflow: auto;
        height: 100%;
    }

    .roadMapContainer {
        padding: 0 38px 30px;
        .switch {
            position: absolute;
            right: 80px;
            bottom: 50px;
        }
    }

    .rightActions{
        position: relative;
        align-self: center;
    }

}