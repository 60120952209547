@import 'constants.scss';

@import './roadMapConstants.scss';


.roadMapContainer {
    position: relative;
    overflow: auto;
    width: 100%;

    &.roadMapContainerEmptyState{
        flex-direction: row;
        position: relative;

        .titlesContainer{
            height: 100%;
        }
        .roadMap{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
        }
    }

    .titlesContainer {

        position: sticky;
        top: 0px;
        z-index: 1;
        background: white;

        width: max-content;
        height: 56px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $textColor;
        .mainColumnTitle {
            min-width: $mainColumnWidth;
            border-right: 1px solid #c4c4c4;
            position: sticky;
            left: 0;
            background: #fff;
        }

        .secondColumnTitle {
            background: $white;
            .header {
                padding-left: 26px;
            }
            .rangeTitle {
                margin-top: 12px;
                min-width: 285px;
                padding-left: 26px;
                color: #5A5A5A;
                font-size: 14px;
                line-height: 21px;
                border-right: 1px dashed #828282;
                padding-bottom: 5px;

                .now {
                    background-color: #E83F43;
                    padding: 0px 4px;
                    border-radius: 6px;
                    color: $white;
                }
            }
        }
    }




    .dataContainer{
        width: max-content;
        .row {

            &:nth-child(odd) {
                .itemContainer {

                    background-color: #FFEA8D;
                    .progress {
                        :global {
                            .MuiLinearProgress-bar {
                                background-color: #FFD000;
                            }
                        }   
                    }
                }

                .cardsContainer {
                    .subColumnContainer {
                        .initiativeCardContainer {
                            border-left: 4px solid #FFD000;
                        }
                    }
                }

                .initiativeLastModifiedDate{
                    background: #FFEA8D;
                }

                
            }
            &:nth-child(even) {
                .itemContainer {

                    background-color: #BBCAFF;
                    .progress {
                        :global {
                            .MuiLinearProgress-bar {
                                background-color: #5E7DEA;
                            }
                        }   
                    }
                }

                .cardsContainer {
                    .subColumnContainer {
                        .initiativeCardContainer {
                            border-left: 4px solid #5E7DEA;
                        }
                    }
                }

                .initiativeLastModifiedDate{
                    background-color: #BBCAFF;
                }

            }

            .mainColumnContainer {
                min-width: $mainColumnWidth;
                border-right: 1px solid #c4c4c4;
                position: sticky;
                left: 0;
                background: #fff;

                .itemContainer {

                    &.unassignedInitiative{
                        background: #FAFAFA;
                    }
                    
                    position: relative;
                    width: 66px;
                    min-height: $roadMapCardHeight;
                    margin-bottom: 10px;
                    border-radius: 6px;
                    clip-path: inset(0);
                    .itemContent {
                        transform: translate(-100%, 0) rotate(-90deg);
                        transform-origin: top right;
                        left: 0;
                        top: 56px;
                        position: sticky;
                        min-width: $roadMapCardHeight;
        
                        .title {
                            color: $textColor;
                            font-size: 14px;
                            padding: 6px 12px 0;
                            margin-bottom: 2px;
                            text-align: right;
                            
                            .text {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                min-height: 35px;
                                line-height: 1.2em;
                                word-break: break-all;
                            }
                        }
                        .progressContainer {
                            height: 10px;
                            padding: 0 12px;
        
                            
        
                            .progressTitle {
                                font-size: 12px;
                                color: $secondaryColor;
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }
            .cardsContainer {
                .subColumnContainer {
                    margin-top: 2px;
                    border-right: 1px dashed #5A5A5A;
                    width: 285px;
    
    
                    .initiativeCardContainer {
                        width: 237px;
                        margin-bottom: $roadMapCardGap;
                        height: $roadMapCardHeight;
                        border-radius: 6px;
                        background-color: #FAFAFA;
                        padding-top: 4px;
                        padding-right: 8px;
                        padding-left: 4px;

                        &.unassignedInitiative{
                            border-left-color: #FAFAFA;

                            .initiativeLastModifiedDate{
                                background: inherit;
                            }
                        }

                        .initiativeTitle {
                            margin-left: 4px;
                            width: fit-content;
                            height: 42px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: $textColor;
                            margin-bottom: 8px;
                           
                            .text {
                                max-height: 42px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }

                        .initiativeTwoColItem{
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-bottom: 4px;
                        }

                        .initiativeReleaseDate {
                            color: #353535;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                        }

                        .initiativeLastModifiedDate{
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 600;
                            color: #5A5A5A;
                            padding: 1px 5px;
                            border-radius: 8px;
                            min-width: 20px;
                            justify-content: center;
                        }

                        .initiativeStatus {
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            color: #003539;
                            background: #EBEBEB;
                            border-radius: 20px;
                            padding: 4px;
                            margin-bottom: 4px;
                            width: fit-content;
                        }

                        .initiativeOwner {
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            color: #353535;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }

    }
}


:export {
    roadMapCardHeight: $roadMapCardHeight;
    roadMapCardGap: $roadMapCardGap;
}