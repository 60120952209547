@import 'commonStyles.scss';
@import 'constants.scss';

.riskStatusBox {
    width: 100%;
    min-width: 102px;
    padding: 6px 8px;
    gap: 4px;
    border-radius: 200px;

    p {
        font-size: 13px;
    }

    & :global(.MuiCircularProgress-root) {
        width: 18px !important;
        height: 18px !important;
        color: #666;
    }
}