.icon{
    font-size: 18px;
    margin-right: 4px;

    &.icon-orange{
        color: #FFA600;
    }

    &.icon-green{
        color: #1A7320;
    }

    &.icon-red{
        color: #ED4337;
    }

    &.icon-blue{
        color: #5E7DEA;
    }

}