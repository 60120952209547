@import 'commonStyles.scss';
@import 'constants.scss';

.createViewContainer {
    width: 472px;
    margin: 24px 0px;
    gap: 32px;

    .gap-1 {
        gap: 4px;
    }

    .createViewTitle {
        color: #303030;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .createViewNameBox {
        height: 48px;
        background-color: #F7F7F8;
        padding: 6px 12px 6px 8px;
        border-radius: 12px;
        color: #2F3B31;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;

        :global(.MuiTextField-root) {
            width: 100%;
            border: none;
            height: 100%;
            box-sizing: border-box;
            input:-webkit-autofill {
                box-shadow: 0 0 0px 1000px #F7F7F8 inset;
            }
        }
    }

    .createViewField {
        display: grid;
        border: 1px solid #F0F0F0;
        border-radius: 16px;
        padding: 12px;
        gap: 20px;

        .createViewFieldIconButton {
            background-color: #F7F7F8;
            border-radius: 100%;
            width: 40px;
            height: 40px;
        }

        .createViewFieldcontent {
            gap: 2px;
            line-height: 20px;

            .contentTitle {
                color: #303030;
                font-weight: 500;
                font-size: 16px;
            }

            .contentDescription {
                color: #656565;
                font-size: 15px;

            }
        }
    }

    .createViewInputBox {
        height: 48px;
        border: 1px solid #009965;
        border-radius: 12px;
        button {
            padding: 8px 10px 8px 10px;
            background-color: #009965;
            border-radius: 10px;
            height: 36px;
            margin-right: 4px;
        }

        &.disabled {
            border: 1px solid $surface2;
            .createViewInputBox__left {
                border-right: 1px solid $surface2;
            }
        }

        .createViewInputBox__left {
            width: 53px;
            text-align: center;
            border-right: 1px solid #009965;
            padding: 0px 14px;
            background: #F5F5F866;
            color: #656565;
            font-size: 15px;
            line-height: 20px;
            
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }


    .createViewLinkInput {
        width: 100%;

        & :global(.MuiInputBase-root) {
            width: 100%;
            border: none;
            height: 100%;
            box-sizing: border-box;

            input {
                padding: 10px 12px;
                border-radius: 0px 12px 12px 0px
            }
        }

        & :global(.MuiOutlinedInput-notchedOutline) {
            border: none;
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0px 1000px #FFF inset;
        }
    }
}