.reportContainer{
    padding: 0 38px;

    .reportControl{
        grid-column-gap: 26px;

        >div{
            flex-basis: 200px;
        }
    }
}

.reportTabs{
    padding: 32px 38px 24px;

    .reportTab{
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        display: inline-block;
    }
}

.reportTabPanelBox{
    margin-bottom: 32px;

    .reportSection{
        max-width: 800px;
        margin: 0 38px 32px;
    }
}

.summaryTabPanelBox{
    margin: 0 38px 32px;

    .summaryEditor{
        :global{
            .tox-edit-area__iframe {
                &:hover{
                    background-color: #FAFAFA;
                }
            }
        }
    }
}

.summaryTab{
    position: relative;
    overflow: initial;

    &:after{
        content: '';
        display: inline-block;
        height: 20px;
        width: 2px;
        background: #828282; 
        position: absolute;
        right: -23px;
        z-index: 1;  
    }
}