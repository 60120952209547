.linearProgressContainer {
    display: block;
    width: 100%;

    .root {
        height: 10px;
        border-radius: 6px;
        background-color: #F5F5F5;
        box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
    }
}