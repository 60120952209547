@import 'constants.scss';
@import 'commonStyles.scss';

.commonButton {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $primaryTextColor;
    background: $surface1;
    padding: 4px 8px;
    border-radius: 8px;
    gap: 8px;

    &:global(.Mui-disabled) {
        opacity: 0.5;
    }

    &.primary {
        background: $brandPrimaryColor;
        color: $textOnPrimary;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;

        &:hover {
            background: $brandGreen100
        }
    }

    &.secondary {
        background-color: $surface1;
        color: $primaryTextColor;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        
        &:hover {
            background: $surface2
        }
    }

    &.shadow {
        border: 1px solid $surface1;
        background: #FFF;
        box-shadow: $simpleButtonShadow;
        color: $primaryTextColor;

        &:hover {
            color: $brandPrimaryColor;
        }
    }

    &.big {
        border-radius: 14px;
        padding: 14px 24px;
    }

    &.large {
        border-radius: 10px;
        padding: 10px 24px;
    }

    &.medium {
        border-radius: 10px;
        padding: 8px 20px;
    }

    &.small {
        border-radius: 8px;
        padding: 6px 12px;
    }
}