@import 'constants.scss';
@import 'commonStyles.scss';

.commonInputContainer {
    .commonInput-label {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; 
        color: $primaryTextColor;
        
        &.disabled{
            color: #757575;
        }
    }

    .commonInput {
        padding: 6px 12px 6px 8px;
        border-radius: 12px;

        :global(.Mui-disabled) {
            color: $secondaryColor;
        }

        :global(.Mui-focused) {
            color: $primaryTextColor;
        }
        
        &.filled {
            background: $surface1;

            :global(.Mui-disabled ) {
                opacity: 0.5;
            }
        }

        &.outline {
            border: 1px solid $surface2;
            background:#FFF;
            box-shadow: $softShadow;
            color: $secondaryColor;
        }

        :global(.MuiOutlinedInput-notchedOutline) {
            border: none;
        }

        :global(.MuiInputBase-input) {
            padding: 8px 0px;
        }
    }

    .commonInput-error {
        font-size: 15px;
        font-weight: 500;
        color: $accentError;
    }

    .commonInput-field.upload {
        input {
            cursor: pointer;
        }
    }
}