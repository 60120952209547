@import 'constants.scss';

.comment-reply {
    display: flex;
    padding: 0;
    background: #f5f5f5;
    overflow: hidden;
    border-radius: 4px;

    &:last-child {
        flex-shrink: 0;
    }
}

.comment-reply-input {
    flex: 1;
    display: flex;
    padding: 9px 12px;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 4px;
    background: unset;

    .avatar {
        margin-right: 8px;
    }
}