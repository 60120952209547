@import 'commonStyles.scss';
@import 'constants.scss';

.prdScoreBox {
    width: 88px;
    padding: 4px 4px 4px 8px;
    gap: 16px;
    border-radius: 200px;
    cursor: pointer;
    
    p {
        font-size: 13px;
    }

    .prdScore-iconBox {
        border-radius: 100%;
        padding: 4px;
        width: 28px;
        height: 28px;
    }
}