@import 'constants.scss';

.textAreaContainer {
    .label {
        font-size: 12px;
        line-height: 21px;
        color: $formElementLabel;

        .required{
            color: $requiredFieldColor;
            font-size: 14px;
        }
    }

    .root {
        background-color: $formElementBackground;
        color: $secondaryColor;
        padding: 12px;
        border-radius: 6px;
        font-size: 14px;

        &.asInput {
            background-color: transparent;
            padding: 7px;
        }

        ::placeholder {
            font-size: 14px;
        }

        .MuiOutlinedInput-root {
            border-radius: 6px;
        }

        &:hover{
            background: #E8E8E8;
            .MuiOutlinedInput-notchedOutline {
                border-color: transparent;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }

        &.focused {
            background: #fff;
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid $primaryColor;
            }
        }

        &.errorField {
            border-color: $fieldErrorColor;

            .MuiOutlinedInput-notchedOutline {
                border: 1px solid $fieldErrorColor;
            }

            &.Mui-focused {
                background: #fff;
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $fieldErrorColor;
                }
            }
            &.Mui-focused fieldset {
                border-color: $fieldErrorColor;
            }
        }

    }

    .disabled {
        background: #FAFAFA;
        padding: 12px;
        font-size: 14px;
        line-height: 1.25em;
        border-radius: 6px;
        min-height: 44px;
        color: $textColor;

        &.asInput {
            background-color: transparent;
        }

        &.placeholder{
            color: #828282;
            opacity: .8;
        }
    }

    .error {
        font-size: 12px;
        color: $fieldErrorColor;
    }
}