@import 'constants.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("assets/fonts/Quicksand-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("assets/fonts/Quicksand-Medium.ttf") format("truetype");
  font-weight: normal;
}


@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("assets/fonts/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Quicksand", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}