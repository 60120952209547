@import 'constants.scss';
@import 'commonStyles.scss';

.dialogComponentRoot {
    .dialogComponentPaper {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 20px;

        &.auto {
            max-width: none;
        }

        &.small {
            width: 410px;
            max-width: inherit;
        }

        &.middle {
            width: 750px;
            max-width: inherit;
            padding: 16px;
            box-sizing: border-box;
        }

        .dialogTitleContainer {
            padding: 16px;
            gap: 8px;
            display: flex;
            flex-direction: column;
        }

        .dialogTitle {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .closeIcon {
                cursor: pointer;
                font-size: 20px;
                color: #828282;
            }

            .dialogTitleLeft {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                text-transform: capitalize;
            }
        }

        .dialogDescription {
            color: #656565;
            font-size: 16px;
            line-height: 20px;
        }

        .dialogContent {
            padding: 0 16px 24px;
            font-size: 14px;
            line-height: 1.4em;
            font-weight: 500;
        }

        .dialogActions {
            padding: 0 16px 16px;
            gap: 15px;

            button {
                box-shadow: none;
                padding: 5px 10px;
                border-radius: 6px;
            }

            .confirmButton {
                &.danger {
                    background: $requiredFieldColor;
                }

                &:global(.Mui-disabled) {
                    &.danger {
                        color: #d1c2c2;
    
                        & :global(.MuiCircularProgress-root) {
                            color: #d1c2c2;
                        }
                    }
                }

            }
        }

    }

    .fullWidth {
        width: 100%;
    }
}