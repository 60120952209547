@import 'constants.scss';

.userInitials {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 12px;
    color: $white;
    background-color: $primaryColorDark;
    flex-shrink: 0;
}
